import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
// import Gallery from "../components/Gallery"
import Contact from "../components/Contact"
import "../theme/main.less"

// import baederGallery from "../data/baederGallery"

const BaederPage = () => {
  return (
    <>
      <HelmetSlot
        title="Bäder"
        description="Badsanierungen vom Fachmann - für ihr Eigenheim, Büro oder Vereinsheim."
      />
      <main>
        <body className="baederpage">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <h1>Bäder & Sanitär</h1>
              <div className="content">
                <p className="text">
                  Vom kleinen Gäste-WC bis zu Großanlagen in Vereinen,
                  Sportstätten oder Schulen - wir stehen bei Neubau,
                  Renovierungen oder Reparaturen an ihrer Seite. Wir stellen die
                  fachgerechte Auswahl, Lieferung und Montage der technischen
                  Gewerke (Waschtische, Duschen, Toiletten, Bidets) sicher,
                  sowie die Montage aller Ver- und Entsorgungsleitungen. Durch
                  regelmäßige Fortbildungen unserer Mitarbeiter sind wir mit dem
                  neuesten Stand der Anforderungen, gesetzlichen Vorgaben und
                  technischen Lösungen bestens vertraut.
                  <br />
                  <br />
                  Noch kein Bild im Kopf? Im unteren Bereich können sie sich
                  gerne einen ersten Eindruck unserer Arbeiten machen.
                </p>
                <Contact />
              </div>
              <br></br>
              {/* <h4>Bilder und Impressionen</h4> */}
              {/* <Gallery items={baederGallery} /> */}
              <div></div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default BaederPage
